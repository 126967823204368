import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30')
];

export const server_loads = [];

export const dictionary = {
		"/(auth)": [7,[2]],
		"/confirm-access/[id]": [16],
		"/dashboard": [17,[3]],
		"/dashboard/actions": [18,[3]],
		"/dashboard/comment-reagir": [19,[3]],
		"/dashboard/messages": [20,[3]],
		"/dashboard/messages/defend": [21,[3]],
		"/dashboard/messages/defend/authority": [22,[3]],
		"/dashboard/proches": [23,[3]],
		"/dashboard/profil": [24,[3]],
		"/finaliser": [25,[4]],
		"/formule": [26,[5]],
		"/invitation": [27],
		"/invitation/[id]": [28],
		"/(auth)/login": [8,[2]],
		"/(auth)/login/reset": [9,[2]],
		"/(auth)/login/restore": [10,[2]],
		"/(auth)/logout": [11,[2]],
		"/(auth)/parent-authorization": [12,[2]],
		"/payment/success": [29],
		"/reseaux-sociaux": [30,[6]],
		"/(auth)/signup": [13,[2]],
		"/(auth)/signup/activation": [14,[2]],
		"/(auth)/signup/missing-fields": [15,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';